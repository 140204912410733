import { AboutPageTemplate } from '../pageTemplates/AboutPageTemplate'
import { HTMLContent } from '../components/Content/Content'
import Layout from '../Layouts/Layout'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data || {}
  const { frontmatter, html } = post || {}
  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        content={html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      post: PropTypes.shape({
        frontmatter: PropTypes.objectOf(PropTypes.any),
        html: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
