import React, { FC, ReactElement } from 'react'

import Content from '../components/Content/Content'
import ReadingContainer from '../components/ReadingContainer/ReadingContainer'

export type AboutPageTemplateProps = {
  title: string
  content: ReactElement | null
  contentComponent?: FC
}

export const AboutPageTemplate: FC<AboutPageTemplateProps> = ({
  title,
  content,
  contentComponent: HTMLComponent = Content,
}) => {
  return (
    <article className="section section--gradient">
      <ReadingContainer>
        <h1>{title}</h1>
        <HTMLComponent>{content}</HTMLComponent>
      </ReadingContainer>
    </article>
  )
}
